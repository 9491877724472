import React from 'react'
import { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import CurrencycodeContext from  './CurrencycodeContext';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';



  

const Currencyconvert = (props) => {
   
  const [CurrencyData, setCurrencyData] = useState([]);
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.code,
      });
   
      let getcurrData = props.setcurrData;
   // console.log(getcurrData);
   const {item} = useContext(CurrencycodeContext);
  //  console.log(item.data.country_name, "-" , item.data.country_code2);
  //  console.log(item.data.currency.code, "-" , item.data.currency.symbol);
   
   // console.log(item);
   useEffect(()=> {
    if(getcurrData){
      if(getcurrData.length > 1) {
        getcurrData = JSON.parse(getcurrData);
        setCurrencyData(getcurrData);
        // console.log(getcurrData[0]);      
      } 
    }
    else {      
      getcurrData =  ['Select a country','Select a currency'];       
      setCurrencyData(getcurrData);
    }
   }, [getcurrData])
    
      
     // console.log(CurrencyData, CurrencyData[0]);
 
  return (
    <div>
 <div className="heading">
    <h6>Regional settings</h6>
</div>
<div className="">
 
{
  CurrencyData ? 
  <div> 
  <div className='mb-3'>
   <label className="form-label">Choose a country/ Region</label>
   <select id='RegcountryCode' className='form-control'>
   <option value={CurrencyData[0]}>{CurrencyData[0]}</option>
   {
    countries.map((item, index) => {
     
      
      return (
        <>
        <option key={index} value={item.label  +" - "+ item.code}>{item.label  +" - "+ item.code} </option>
        </>
      )
    })
   }
  
   </select>
   </div>
   <div className='mb-3'>
   <label className="form-label"> Choose a currency </label>
   <select id='regCurrencySet' className='form-control'>
   <option value={CurrencyData[1]}>{CurrencyData[1]}</option>
   {
    currencies.map((item, index) => {
     
      
      return (
        <>
        <option key={index} value={item.code +" - "+ item.symbol}>{item.code +" - "+ item.symbol} </option>
        </>
      )
    })
   }
  
   </select>
   </div>
   
  </div>
  
  : <div> 
  
  </div>
}
      
     
</div>
 
    </div>
  )
}
const currencies =  [{
    "symbol": "$",
    "name": "US Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "USD",
    "name_plural": "US dollars"
},
 {
    "symbol": "CA$",
    "name": "Canadian Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CAD",
    "name_plural": "Canadian dollars"
},
 {
    "symbol": "€",
    "name": "Euro",
    "symbol_native": "€",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EUR",
    "name_plural": "euros"
},
{
    "symbol": "AED",
    "name": "United Arab Emirates Dirham",
    "symbol_native": "د.إ.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AED",
    "name_plural": "UAE dirhams"
},
{
    "symbol": "Af",
    "name": "Afghan Afghani",
    "symbol_native": "؋",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "AFN",
    "name_plural": "Afghan Afghanis"
},
{
    "symbol": "ALL",
    "name": "Albanian Lek",
    "symbol_native": "Lek",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ALL",
    "name_plural": "Albanian lekë"
},
{
    "symbol": "AMD",
    "name": "Armenian Dram",
    "symbol_native": "դր.",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "AMD",
    "name_plural": "Armenian drams"
},
{
    "symbol": "AR$",
    "name": "Argentine Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ARS",
    "name_plural": "Argentine pesos"
},
{
    "symbol": "AU$",
    "name": "Australian Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AUD",
    "name_plural": "Australian dollars"
},
{
    "symbol": "man.",
    "name": "Azerbaijani Manat",
    "symbol_native": "ман.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AZN",
    "name_plural": "Azerbaijani manats"
},
{
    "symbol": "KM",
    "name": "Bosnia-Herzegovina Convertible Mark",
    "symbol_native": "KM",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BAM",
    "name_plural": "Bosnia-Herzegovina convertible marks"
},
{
    "symbol": "Tk",
    "name": "Bangladeshi Taka",
    "symbol_native": "৳",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BDT",
    "name_plural": "Bangladeshi takas"
},
{
    "symbol": "BGN",
    "name": "Bulgarian Lev",
    "symbol_native": "лв.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BGN",
    "name_plural": "Bulgarian leva"
},
{
    "symbol": "BD",
    "name": "Bahraini Dinar",
    "symbol_native": "د.ب.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "BHD",
    "name_plural": "Bahraini dinars"
},
{
    "symbol": "FBu",
    "name": "Burundian Franc",
    "symbol_native": "FBu",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "BIF",
    "name_plural": "Burundian francs"
},
{
    "symbol": "BN$",
    "name": "Brunei Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BND",
    "name_plural": "Brunei dollars"
},
{
    "symbol": "Bs",
    "name": "Bolivian Boliviano",
    "symbol_native": "Bs",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BOB",
    "name_plural": "Bolivian bolivianos"
},
{
    "symbol": "R$",
    "name": "Brazilian Real",
    "symbol_native": "R$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BRL",
    "name_plural": "Brazilian reals"
},
{
    "symbol": "BWP",
    "name": "Botswanan Pula",
    "symbol_native": "P",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BWP",
    "name_plural": "Botswanan pulas"
},
{
    "symbol": "Br",
    "name": "Belarusian Ruble",
    "symbol_native": "руб.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BYN",
    "name_plural": "Belarusian rubles"
},
{
    "symbol": "BZ$",
    "name": "Belize Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BZD",
    "name_plural": "Belize dollars"
},
{
    "symbol": "CDF",
    "name": "Congolese Franc",
    "symbol_native": "FrCD",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CDF",
    "name_plural": "Congolese francs"
},
{
    "symbol": "CHF",
    "name": "Swiss Franc",
    "symbol_native": "CHF",
    "decimal_digits": 2,
    "rounding": 0.05,
    "code": "CHF",
    "name_plural": "Swiss francs"
},
{
    "symbol": "CL$",
    "name": "Chilean Peso",
    "symbol_native": "$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "CLP",
    "name_plural": "Chilean pesos"
},
{
    "symbol": "CN¥",
    "name": "Chinese Yuan",
    "symbol_native": "CN¥",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CNY",
    "name_plural": "Chinese yuan"
},
{
    "symbol": "CO$",
    "name": "Colombian Peso",
    "symbol_native": "$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "COP",
    "name_plural": "Colombian pesos"
},
{
    "symbol": "₡",
    "name": "Costa Rican Colón",
    "symbol_native": "₡",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "CRC",
    "name_plural": "Costa Rican colóns"
},
{
    "symbol": "CV$",
    "name": "Cape Verdean Escudo",
    "symbol_native": "CV$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CVE",
    "name_plural": "Cape Verdean escudos"
},
{
    "symbol": "Kč",
    "name": "Czech Republic Koruna",
    "symbol_native": "Kč",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CZK",
    "name_plural": "Czech Republic korunas"
},
{  
    "symbol": "Fdj",
    "name": "Djiboutian Franc",
    "symbol_native": "Fdj",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "DJF",
    "name_plural": "Djiboutian francs"
},
 {
    "symbol": "Dkr",
    "name": "Danish Krone",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DKK",
    "name_plural": "Danish kroner"
},
{
    "symbol": "RD$",
    "name": "Dominican Peso",
    "symbol_native": "RD$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DOP",
    "name_plural": "Dominican pesos"
},
{
    "symbol": "DA",
    "name": "Algerian Dinar",
    "symbol_native": "د.ج.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DZD",
    "name_plural": "Algerian dinars"
},
{
    "symbol": "Ekr",
    "name": "Estonian Kroon",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EEK",
    "name_plural": "Estonian kroons"
},
{
    "symbol": "EGP",
    "name": "Egyptian Pound",
    "symbol_native": "ج.م.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EGP",
    "name_plural": "Egyptian pounds"
},
  {
    "symbol": "Nfk",
    "name": "Eritrean Nakfa",
    "symbol_native": "Nfk",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ERN",
    "name_plural": "Eritrean nakfas"
},
{
    "symbol": "Br",
    "name": "Ethiopian Birr",
    "symbol_native": "Br",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ETB",
    "name_plural": "Ethiopian birrs"
},
 {
    "symbol": "£",
    "name": "British Pound Sterling",
    "symbol_native": "£",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GBP",
    "name_plural": "British pounds sterling"
},
{
    "symbol": "GEL",
    "name": "Georgian Lari",
    "symbol_native": "GEL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GEL",
    "name_plural": "Georgian laris"
},
{
    "symbol": "GH₵",
    "name": "Ghanaian Cedi",
    "symbol_native": "GH₵",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GHS",
    "name_plural": "Ghanaian cedis"
},
  {
    "symbol": "FG",
    "name": "Guinean Franc",
    "symbol_native": "FG",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "GNF",
    "name_plural": "Guinean francs"
},
{
    "symbol": "GTQ",
    "name": "Guatemalan Quetzal",
    "symbol_native": "Q",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GTQ",
    "name_plural": "Guatemalan quetzals"
},
  {
    "symbol": "HK$",
    "name": "Hong Kong Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HKD",
    "name_plural": "Hong Kong dollars"
},
 {
    "symbol": "HNL",
    "name": "Honduran Lempira",
    "symbol_native": "L",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HNL",
    "name_plural": "Honduran lempiras"
},
 {
    "symbol": "kn",
    "name": "Croatian Kuna",
    "symbol_native": "kn",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HRK",
    "name_plural": "Croatian kunas"
},
 {
    "symbol": "Ft",
    "name": "Hungarian Forint",
    "symbol_native": "Ft",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "HUF",
    "name_plural": "Hungarian forints"
},
  {
    "symbol": "Rp",
    "name": "Indonesian Rupiah",
    "symbol_native": "Rp",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IDR",
    "name_plural": "Indonesian rupiahs"
},
  {
    "symbol": "₪",
    "name": "Israeli New Sheqel",
    "symbol_native": "₪",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ILS",
    "name_plural": "Israeli new sheqels"
},
{
    "symbol": "₹",
    "name": "Indian Rupee",
    "symbol_native": "টকা",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "INR",
    "name_plural": "Indian rupees"
},
  {
    "symbol": "IQD",
    "name": "Iraqi Dinar",
    "symbol_native": "د.ع.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IQD",
    "name_plural": "Iraqi dinars"
},
{
    "symbol": "IRR",
    "name": "Iranian Rial",
    "symbol_native": "﷼",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IRR",
    "name_plural": "Iranian rials"
},
  {
    "symbol": "Ikr",
    "name": "Icelandic Króna",
    "symbol_native": "kr",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ISK",
    "name_plural": "Icelandic krónur"
},
{
    "symbol": "J$",
    "name": "Jamaican Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "JMD",
    "name_plural": "Jamaican dollars"
},
{
    "symbol": "JD",
    "name": "Jordanian Dinar",
    "symbol_native": "د.أ.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "JOD",
    "name_plural": "Jordanian dinars"
},
 {
    "symbol": "¥",
    "name": "Japanese Yen",
    "symbol_native": "￥",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "JPY",
    "name_plural": "Japanese yen"
},
 {
    "symbol": "Ksh",
    "name": "Kenyan Shilling",
    "symbol_native": "Ksh",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KES",
    "name_plural": "Kenyan shillings"
},
  {
    "symbol": "KHR",
    "name": "Cambodian Riel",
    "symbol_native": "៛",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KHR",
    "name_plural": "Cambodian riels"
},
{
    "symbol": "CF",
    "name": "Comorian Franc",
    "symbol_native": "FC",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "KMF",
    "name_plural": "Comorian francs"
},
 {
    "symbol": "₩",
    "name": "South Korean Won",
    "symbol_native": "₩",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "KRW",
    "name_plural": "South Korean won"
},
  {
    "symbol": "KD",
    "name": "Kuwaiti Dinar",
    "symbol_native": "د.ك.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "KWD",
    "name_plural": "Kuwaiti dinars"
},
  {
    "symbol": "KZT",
    "name": "Kazakhstani Tenge",
    "symbol_native": "тңг.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KZT",
    "name_plural": "Kazakhstani tenges"
},
{
    "symbol": "L.L.",
    "name": "Lebanese Pound",
    "symbol_native": "ل.ل.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "LBP",
    "name_plural": "Lebanese pounds"
},
{
    "symbol": "SLRs",
    "name": "Sri Lankan Rupee",
    "symbol_native": "SL Re",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LKR",
    "name_plural": "Sri Lankan rupees"
},
 {
    "symbol": "Lt",
    "name": "Lithuanian Litas",
    "symbol_native": "Lt",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LTL",
    "name_plural": "Lithuanian litai"
},
{
    "symbol": "Ls",
    "name": "Latvian Lats",
    "symbol_native": "Ls",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LVL",
    "name_plural": "Latvian lati"
},
{
    "symbol": "LD",
    "name": "Libyan Dinar",
    "symbol_native": "د.ل.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "LYD",
    "name_plural": "Libyan dinars"
},
{
    "symbol": "MAD",
    "name": "Moroccan Dirham",
    "symbol_native": "د.م.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MAD",
    "name_plural": "Moroccan dirhams"
},
  {
    "symbol": "MDL",
    "name": "Moldovan Leu",
    "symbol_native": "MDL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MDL",
    "name_plural": "Moldovan lei"
},
  {
    "symbol": "MGA",
    "name": "Malagasy Ariary",
    "symbol_native": "MGA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MGA",
    "name_plural": "Malagasy Ariaries"
},
  {
    "symbol": "MKD",
    "name": "Macedonian Denar",
    "symbol_native": "MKD",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MKD",
    "name_plural": "Macedonian denari"
},
  {
    "symbol": "MMK",
    "name": "Myanma Kyat",
    "symbol_native": "K",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MMK",
    "name_plural": "Myanma kyats"
},
{
    "symbol": "MOP$",
    "name": "Macanese Pataca",
    "symbol_native": "MOP$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MOP",
    "name_plural": "Macanese patacas"
},
  {
    "symbol": "MURs",
    "name": "Mauritian Rupee",
    "symbol_native": "MURs",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MUR",
    "name_plural": "Mauritian rupees"
},
  {
    "symbol": "MX$",
    "name": "Mexican Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MXN",
    "name_plural": "Mexican pesos"
},
  {
    "symbol": "RM",
    "name": "Malaysian Ringgit",
    "symbol_native": "RM",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MYR",
    "name_plural": "Malaysian ringgits"
},
{
    "symbol": "MTn",
    "name": "Mozambican Metical",
    "symbol_native": "MTn",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MZN",
    "name_plural": "Mozambican meticals"
},
  {
    "symbol": "N$",
    "name": "Namibian Dollar",
    "symbol_native": "N$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NAD",
    "name_plural": "Namibian dollars"
},
  {
    "symbol": "₦",
    "name": "Nigerian Naira",
    "symbol_native": "₦",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NGN",
    "name_plural": "Nigerian nairas"
},
 {
    "symbol": "C$",
    "name": "Nicaraguan Córdoba",
    "symbol_native": "C$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NIO",
    "name_plural": "Nicaraguan córdobas"
},
{
    "symbol": "Nkr",
    "name": "Norwegian Krone",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NOK",
    "name_plural": "Norwegian kroner"
},
  {
    "symbol": "NPRs",
    "name": "Nepalese Rupee",
    "symbol_native": "नेरू",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NPR",
    "name_plural": "Nepalese rupees"
},
  {
    "symbol": "NZ$",
    "name": "New Zealand Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NZD",
    "name_plural": "New Zealand dollars"
},
  {
    "symbol": "OMR",
    "name": "Omani Rial",
    "symbol_native": "ر.ع.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "OMR",
    "name_plural": "Omani rials"
},
  {
    "symbol": "B/.",
    "name": "Panamanian Balboa",
    "symbol_native": "B/.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PAB",
    "name_plural": "Panamanian balboas"
},
  {
    "symbol": "S/.",
    "name": "Peruvian Nuevo Sol",
    "symbol_native": "S/.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PEN",
    "name_plural": "Peruvian nuevos soles"
},
{
    "symbol": "₱",
    "name": "Philippine Peso",
    "symbol_native": "₱",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PHP",
    "name_plural": "Philippine pesos"
},
{
    "symbol": "PKRs",
    "name": "Pakistani Rupee",
    "symbol_native": "₨",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "PKR",
    "name_plural": "Pakistani rupees"
},
  {
    "symbol": "zł",
    "name": "Polish Zloty",
    "symbol_native": "zł",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PLN",
    "name_plural": "Polish zlotys"
},
  {
    "symbol": "₲",
    "name": "Paraguayan Guarani",
    "symbol_native": "₲",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "PYG",
    "name_plural": "Paraguayan guaranis"
},
 {
    "symbol": "QR",
    "name": "Qatari Rial",
    "symbol_native": "ر.ق.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "QAR",
    "name_plural": "Qatari rials"
},
 {
    "symbol": "RON",
    "name": "Romanian Leu",
    "symbol_native": "RON",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "RON",
    "name_plural": "Romanian lei"
},
{
    "symbol": "din.",
    "name": "Serbian Dinar",
    "symbol_native": "дин.",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "RSD",
    "name_plural": "Serbian dinars"
},
  {
    "symbol": "RUB",
    "name": "Russian Ruble",
    "symbol_native": "₽.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "RUB",
    "name_plural": "Russian rubles"
},
  {
    "symbol": "RWF",
    "name": "Rwandan Franc",
    "symbol_native": "FR",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "RWF",
    "name_plural": "Rwandan francs"
},
  {
    "symbol": "SR",
    "name": "Saudi Riyal",
    "symbol_native": "ر.س.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SAR",
    "name_plural": "Saudi riyals"
},
  {
    "symbol": "SDG",
    "name": "Sudanese Pound",
    "symbol_native": "SDG",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SDG",
    "name_plural": "Sudanese pounds"
},
{
    "symbol": "Skr",
    "name": "Swedish Krona",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SEK",
    "name_plural": "Swedish kronor"
},
  {
    "symbol": "S$",
    "name": "Singapore Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SGD",
    "name_plural": "Singapore dollars"
},
  {
    "symbol": "Ssh",
    "name": "Somali Shilling",
    "symbol_native": "Ssh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "SOS",
    "name_plural": "Somali shillings"
},
  {
    "symbol": "SY£",
    "name": "Syrian Pound",
    "symbol_native": "ل.س.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "SYP",
    "name_plural": "Syrian pounds"
},
  {
    "symbol": "฿",
    "name": "Thai Baht",
    "symbol_native": "฿",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "THB",
    "name_plural": "Thai baht"
},
 {
    "symbol": "DT",
    "name": "Tunisian Dinar",
    "symbol_native": "د.ت.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "TND",
    "name_plural": "Tunisian dinars"
},
  {
    "symbol": "T$",
    "name": "Tongan Paʻanga",
    "symbol_native": "T$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TOP",
    "name_plural": "Tongan paʻanga"
},
  {
    "symbol": "TL",
    "name": "Turkish Lira",
    "symbol_native": "TL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TRY",
    "name_plural": "Turkish Lira"
},
{
    "symbol": "TT$",
    "name": "Trinidad and Tobago Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TTD",
    "name_plural": "Trinidad and Tobago dollars"
},
  {
    "symbol": "NT$",
    "name": "New Taiwan Dollar",
    "symbol_native": "NT$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TWD",
    "name_plural": "New Taiwan dollars"
},
 {
    "symbol": "TSh",
    "name": "Tanzanian Shilling",
    "symbol_native": "TSh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "TZS",
    "name_plural": "Tanzanian shillings"
},
  {
    "symbol": "₴",
    "name": "Ukrainian Hryvnia",
    "symbol_native": "₴",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "UAH",
    "name_plural": "Ukrainian hryvnias"
},
{
    "symbol": "USh",
    "name": "Ugandan Shilling",
    "symbol_native": "USh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "UGX",
    "name_plural": "Ugandan shillings"
},
{
    "symbol": "$U",
    "name": "Uruguayan Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "UYU",
    "name_plural": "Uruguayan pesos"
},
  {
    "symbol": "UZS",
    "name": "Uzbekistan Som",
    "symbol_native": "UZS",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "UZS",
    "name_plural": "Uzbekistan som"
},
  {
    "symbol": "Bs.F.",
    "name": "Venezuelan Bolívar",
    "symbol_native": "Bs.F.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "VEF",
    "name_plural": "Venezuelan bolívars"
},
  {
    "symbol": "₫",
    "name": "Vietnamese Dong",
    "symbol_native": "₫",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "VND",
    "name_plural": "Vietnamese dong"
},
  {
    "symbol": "FCFA",
    "name": "CFA Franc BEAC",
    "symbol_native": "FCFA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "XAF",
    "name_plural": "CFA francs BEAC"
},
  {
    "symbol": "CFA",
    "name": "CFA Franc BCEAO",
    "symbol_native": "CFA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "XOF",
    "name_plural": "CFA francs BCEAO"
},
  {
    "symbol": "YR",
    "name": "Yemeni Rial",
    "symbol_native": "ر.ي.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "YER",
    "name_plural": "Yemeni rials"
},
  {
    "symbol": "R",
    "name": "South African Rand",
    "symbol_native": "R",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ZAR",
    "name_plural": "South African rand"
},
  {
    "symbol": "ZK",
    "name": "Zambian Kwacha",
    "symbol_native": "ZK",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ZMK",
    "name_plural": "Zambian kwachas"
},
  {
    "symbol": "ZWL$",
    "name": "Zimbabwean Dollar",
    "symbol_native": "ZWL$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ZWL",
    "name_plural": "Zimbabwean Dollar"
}]

  
const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
      code: 'AE',
      label: 'United Arab Emirates',
      phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
      code: 'AG',
      label: 'Antigua and Barbuda',
      phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
      code: 'AU',
      label: 'Australia',
      phone: '61',
      suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
      code: 'BA',
      label: 'Bosnia and Herzegovina',
      phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
      code: 'CA',
      label: 'Canada',
      phone: '1',
      suggested: true,
    },
    {
      code: 'CC',
      label: 'Cocos (Keeling) Islands',
      phone: '61',
    },
    {
      code: 'CD',
      label: 'Congo, Democratic Republic of the',
      phone: '243',
    },
    {
      code: 'CF',
      label: 'Central African Republic',
      phone: '236',
    },
    {
      code: 'CG',
      label: 'Congo, Republic of the',
      phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
      code: 'DE',
      label: 'Germany',
      phone: '49',
      suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
      code: 'DO',
      label: 'Dominican Republic',
      phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
      code: 'FK',
      label: 'Falkland Islands (Malvinas)',
      phone: '500',
    },
    {
      code: 'FM',
      label: 'Micronesia, Federated States of',
      phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
      code: 'FR',
      label: 'France',
      phone: '33',
      suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
      code: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
      phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
      code: 'HM',
      label: 'Heard Island and McDonald Islands',
      phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
      code: 'IO',
      label: 'British Indian Ocean Territory',
      phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
      code: 'IR',
      label: 'Iran, Islamic Republic of',
      phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
      code: 'JP',
      label: 'Japan',
      phone: '81',
      suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
      code: 'KN',
      label: 'Saint Kitts and Nevis',
      phone: '1-869',
    },
    {
      code: 'KP',
      label: "Korea, Democratic People's Republic of",
      phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
      code: 'LA',
      label: "Lao People's Democratic Republic",
      phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
      code: 'MD',
      label: 'Moldova, Republic of',
      phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
      code: 'MF',
      label: 'Saint Martin (French part)',
      phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
      code: 'MK',
      label: 'Macedonia, the Former Yugoslav Republic of',
      phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
      code: 'MP',
      label: 'Northern Mariana Islands',
      phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
      code: 'PM',
      label: 'Saint Pierre and Miquelon',
      phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
      code: 'PS',
      label: 'Palestine, State of',
      phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
      code: 'SJ',
      label: 'Svalbard and Jan Mayen',
      phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
      code: 'ST',
      label: 'Sao Tome and Principe',
      phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
      code: 'SX',
      label: 'Sint Maarten (Dutch part)',
      phone: '1-721',
    },
    {
      code: 'SY',
      label: 'Syrian Arab Republic',
      phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
      code: 'TC',
      label: 'Turks and Caicos Islands',
      phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
      code: 'TF',
      label: 'French Southern Territories',
      phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
      code: 'TT',
      label: 'Trinidad and Tobago',
      phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
      code: 'TW',
      label: 'Taiwan, Republic of China',
      phone: '886',
    },
    {
      code: 'TZ',
      label: 'United Republic of Tanzania',
      phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
      code: 'US',
      label: 'United States',
      phone: '1',
      suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
      code: 'VA',
      label: 'Holy See (Vatican City State)',
      phone: '379',
    },
    {
      code: 'VC',
      label: 'Saint Vincent and the Grenadines',
      phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
      code: 'VG',
      label: 'British Virgin Islands',
      phone: '1-284',
    },
    {
      code: 'VI',
      label: 'US Virgin Islands',
      phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  ];

export default Currencyconvert
